import { render, staticRenderFns } from "./FlavourTeaser.vue?vue&type=template&id=0a929b89&scoped=true&"
import script from "./FlavourTeaser.vue?vue&type=script&lang=js&"
export * from "./FlavourTeaser.vue?vue&type=script&lang=js&"
import style0 from "./FlavourTeaser.vue?vue&type=style&index=0&id=0a929b89&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a929b89",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnimationGsapScrollTranslate: require('/vercel/path0/components/elements/Animations/GsapScrollTranslate.vue').default,Button: require('/vercel/path0/components/elements/Button.vue').default,Picture: require('/vercel/path0/components/elements/Picture.vue').default,Link: require('/vercel/path0/components/elements/Link.vue').default,AnimationCustomFlagIn: require('/vercel/path0/components/elements/Animations/CustomFlagIn.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
