
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      v1Distance: -100
    };
  },
  mounted() {
    this.v1Distance = window.innerWidth < 1024 ? -20 : -100;

    window.addEventListener('resize', () => {
      this.v1Distance = window.innerWidth < 1024 ? -20 : -100;
    });
  },
  methods: {
    getUrl(index) {
      const url = this.data?.list[index]?.link.isExternal
        ? this.data?.list[index]?.link.externalLink || '#'
        : this.getPageByRef(this.data?.list[index]?.link?.link?._ref)?.url?.fullPath || '#';
      return url;
    }
  }
};
